.dialog_title_rate_contribution {
    > .text_title {
        font-size: 1.4rem !important;
        font-weight: bold !important;
        color: #2c2c2cef !important;
        text-align: center !important;
    }
}

.dialog_content_rate_contribution {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 0.8rem !important;

    > .text_subtitle {
        font-weight: 500 !important;
        color: #2c2c2ccc !important;
    }

    svg {
        font-size: 3rem !important;
    }

    .btnRate {
        color:#FFC000 !important;
        border-color: #FFC000 !important;
    }
}

@media screen and (max-width: 840px){
    .dialog_title_rate_contribution {
        > h2 {
            font-size: 1.4rem !important;
        }
    }
}
