.contribution_item {
    padding: 1.8rem 1.2rem 0.8rem !important;
    width: 90% !important;

    > h2 {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;

        text-align: center !important;
        font-size: 1.6rem !important;
        font-weight: 500 !important;
        margin-bottom: 0.8rem !important;

        &::after {
            content: "" !important;
            width: 2rem !important;
            height: 2px !important;
            background: #b50000 !important;
            margin-top: 0.4rem !important;
        }
    }

    > h3 {
        text-align: justify !important;
        font-size: 1.2rem !important;
        font-weight: 200 !important;
        margin-bottom: 1.4rem !important;
        line-height: 24px !important;
        letter-spacing: 0.25px !important;
        background: #ededed87 !important;
        padding: 0.6rem !important;
        border-radius: 4px !important;
    }

    > .contribution_details {
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;

        margin-top: 1rem !important;

        > h6 {
            width: 100% !important;

            font-size: 1.2rem !important;

            > span {
                color: #2c2c2ca7 !important;
                font-size: 1.2rem !important;
            }
        }

        > button + h6 {
            padding-top: 0.8rem !important;
            border-top: 1px solid #42424245 !important;
        }

        > div > .rating {
            padding: 0.4rem 0 !important;
        }

        > div > .rating svg {
            font-size: 1.4rem !important;
        }

        > div > .num_rate {
            font-weight: bold !important;
            padding: 0 0.4rem !important;
            color: #2c2c2cc7 !important;
            border: 1px solid #2c2c2cc7 !important;
            border-radius: 4px !important;
        }
    }

    & + & {
        border-top: 2px solid #cbcbcb !important;
    }
}

@media screen and (max-width: 840px) {
    .contribution_item {
        padding: 1.8rem 1.2rem 0.8rem !important;
        width: 99% !important;
    
        > .contribution_details {
            margin-top: 0.5rem !important;
    
            > h6 {
                font-size: 1rem !important;
    
                > span {
                    font-size: 1rem !important;
                }
            }
    
            > button + h6 {
                padding-top: 0.4rem !important;
            }
    
            > .rating {
                padding: 0.2rem 0 !important;
            }
    
            > .rating svg {
                font-size: 1.4rem !important;
            }
        }
    }
}