.area_title_page {
    display: flex !important;
    align-items: center !important;
    gap: 0.5rem !important;

    margin-bottom: 1.2rem !important;
    margin-left: 1.2rem !important;

    > svg {
        font-size: 2.4rem !important;
    }

    > h6 {
        font-size: 1.4rem !important;
        font-weight: 500 !important;
    }
}

@media screen and (max-width: 840px) {
    .area_title_page {
        > svg {
            font-size: 1.8rem !important;
        }
    
        > h6 {
            font-size: 1.2rem !important;
        }
    }
}