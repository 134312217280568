.dialog_title_new_contribution {
    background: var(--red-primary) !important;

    color: #fff !important;
    text-align: center !important;
    font-size: 1.4rem !important;
}

.dialog_title_new_contribution.fal {
    background: var(--blue-primary) !important;
}