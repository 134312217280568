.dialog_title_auth_my_library {
    background: var(--red-primary) !important;

    :first-child {
        color: #fff !important;
        text-align: center !important;
        font-size: 1.2rem !important;
        font-weight: 500 !important;
    }
}

.dialog_title_auth_my_library.fal {
    background: var(--blue-primary) !important;
}

.info_confirmation {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    margin-bottom: 0.8rem !important;
    color: #3a3b3a !important;
}