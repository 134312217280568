.area_chart_emojis {
    width: 100% !important;
    padding: 0.8em 0 !important;
    border-left: 1px solid #cbcbcb !important;
    border-top: 1px solid #cbcbcb !important;
    border-bottom: 1px solid #cbcbcb !important;
    border-radius: 4px !important;

    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;

    position: relative !important;

    > h6 {
        font-size: 1.8rem !important;
        text-transform: capitalize !important;
        color: #2c2c2c !important;

        &.have_evaluates {
            position: absolute !important;
            top: 1.8rem !important;
            z-index: 1 !important;
        }
    }

    > .header_labels {
        display: flex !important;
        justify-content: space-evenly !important;
        width: 100% !important;
        flex-wrap: wrap !important;

        .info_label {
            display: flex !important;
            align-items: center !important;
            gap: 0.4rem !important;
            padding: 0 0.2rem !important;
    
            > .area_info_color_label {
                width: 2rem !important;
                height: 1rem !important;
            }
    
            > .label {
                font-weight: 500 !important;
                color: #484848 !important;
                text-transform: uppercase !important;
            }
        }
    }
}

@media screen and (max-width: 840px){
    .area_chart_emojis {
        margin: 1rem 0 !important;
        border: none !important;
    }
}