.box_quiz {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;

    .title_discipline_evaluation {
        text-align: center !important;
        text-transform: uppercase !important;
        font-size: 2.2rem !important;
        font-weight: bold !important;
    }
    
    .subtitle_discipline_evaluation {
        text-align: center !important;
        font-size: 1.1rem !important;
        color: dimgrey !important;
        font-weight: bold !important;
        margin-bottom: 2rem !important;
    }

    > form {
        display: flex !important;
        flex-direction: column !important;
        margin-bottom: 2rem !important;

        > button { 
            align-self: center !important;
            margin-top: 0.8rem !important;
        }
    }

    > .box_evaluated {
        padding: 0.8rem !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        gap: 0.8rem !important;
        border: 1px solid #3f8a0078 !important;
        border-radius: 4px !important;
        background: #1fa01394 !important;

        > :first-child {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            gap: 0.8rem !important;

            color: #fff !important;
            font-size: 1.4rem !important;
            font-weight: bold !important;
            text-transform: uppercase !important;
        }

        > :last-child {
            color: #136306 !important;
            font-size: 1.2rem !important;
            font-weight: 500 !important;
        }
    }
}