.box_question {
    width: 100% !important;
    background: #f6f6f6 !important;
    border-radius: 4px !important;
    padding: 0.6rem !important;

    display: flex !important;
    flex-direction: column !important;
    gap: 0.8rem !important;

    border: 1px solid #dae7ffd9 !important;

    > .header_question {
        display: flex !important;
        align-items: center !important;
        gap: 0.8rem !important;

        > .num_item {
            background: #417bd8db !important;
            width: 2rem !important;
            height: 2rem !important;

            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            border-radius: 32px !important;

            color: #fff !important;
            font-weight: bold !important;
            font-size: 1rem !important;
        }

        >:last-child {
            font-size: 1.1rem !important;

            > span {
                color: var(--red-primary) !important;
                margin-right: 0.2rem !important;
                cursor: pointer !important;
            }
        }
    }

    > .dividers {
        width: 100% !important;
        height: 1px !important;
        background-color: rgba(102, 102, 102, 0.224) !important;
        align-self: center !important;
        border-radius: 4px !important;
    }

    & + & {
        margin-top: 0.8rem !important;
    }
}