.area_evaluate_unit {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 1.2rem !important;

    padding-left: 1rem !important;

    > .info_evaluate {
        text-align: center !important;
        font-size: 1.2rem !important;
        font-weight: 500 !important;
        color: #484848 !important;
    }

    > button {
        align-self: center !important;
    }
}

.chart_emojis {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    padding: 0 0.4rem !important;
}

@media screen and (max-width: 840px) {
    .area_evaluate_unit {
        gap: 1rem !important;
        padding: 0 0.2rem !important;
    
        > .info_evaluate {
            font-size: 1rem !important;
        }
    }
}