.dialog_title_comments {
    background: var(--red-primary) !important;

    color: #fff !important;
    text-align: center !important;
    font-size: 1.4rem !important;

    &.fal {
        background: var(--blue-primary) !important;
    }
}

.area_comments {
    border: 1px solid #cbcbcb !important;
    border-radius: 4px !important;
    min-height: 10vh !important;
    max-height: 50vh !important;
    overflow-y: auto !important;
    padding: 0.6rem !important;

    > .no_comments {
        width: 100% !important;
        min-height: 10vh !important;
        max-height: 50vh !important;

        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

        color: #cbcbcb !important;
    }

    > .area_comment {
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start !important;
        
        &.is_auth {
            align-items: flex-end !important;
        }

        > .author {
            font-size: 0.8rem !important;
            max-width: 50% !important;
        }

        > .comment {
            max-width: 50% !important;
            background: #cbcbcbcc !important;
            border: 1px solid #cbcbcb !important;
            border-radius: 4px !important;
            padding: 0.6rem !important;
            background: #ffc00085 !important;
            
            &.is_auth {
                background: #b5000085 !important;
            }

        }

        & + .area_comment {
            margin-top: 0.8rem !important;
        }
    }
}

.dialog_actions_comments {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;

    > form {
        width: 100% !important;
        display: flex !important;
        gap: 0.4rem !important;
    
        > button {
            > svg {
                font-size: 2rem !important;
                color: var(--red-primary) !important;
            }
        }
    }

    &.fal {
        > form {
            > button {
                > svg {
                    color: var(--blue-primary) !important;
                }
            }
        }   
    }
}