.login {
    border-bottom: 1px dotted #cbcbcb !important;
    padding: 0.6rem !important;
    width: 100% !important;
    min-height: 20rem !important;

    display: flex !important;
    flex-direction: column !important;
    gap: 3rem !important;
    justify-content: space-between !important;
    align-items: center !important;

    .title_subject_login {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;

        > h4 {
            font-size: 1.2rem !important;
            color: #818181 !important;
            text-align: center !important;
        }
    
        > h5 {
            font-size: 1.2rem !important;
        }
    }

    > .credentials {
        width: 90% !important;
    }

    > .credentials > form {
        margin-bottom: 0.8rem !important;

        display: flex !important;
        flex-direction: column !important;
        gap: 0.8rem !important;

        > button:disabled {
            border: 1px solid #b5000085 !important;
            background-color: #cbcbcb41 !important;
        }

        > button[name="remember_password"] {
            align-self: flex-end !important;
        }
    }

    > .baseboard {
        font-size: 0.8rem !important;
        font-weight: 500 !important;
        color: #818181 !important;
    }
}