.area_play {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    margin-top: 1.2rem !important;

    > .player {
        width: 80% !important;
        height: 45rem !important;
    }
}

@media screen and (max-width: 1540px) {
    .area_play {
        padding: 1 0.4rem !important;

        > .player {
            width: 90% !important;
            height: 40rem !important;
        }
    }
}

@media screen and (max-width: 1200px) {
    .area_play {
        padding: 1 0.4rem !important;

        > .player {
            width: 90% !important;
            height: 25rem !important;
        }
    }
}

@media screen and (max-width: 840px) {
    .area_play {
        padding: 1 0.4rem !important;

        > .player {
            width: 98% !important;
            height: 15rem !important;
        }
    }
}