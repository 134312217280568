.area_void {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    margin-bottom: 0.8rem !important;

    .void {
        font-size: 1.4rem !important;
        color: #969595 !important;
        border-radius: 4px !important;
        border: 1px solid #aeaeae4c !important;
        padding: 0.6rem !important;
    
        > small {
            display: block !important;
            text-align: center !important;
        }
    }
}