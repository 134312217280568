.area_how_to_study {
    display: flex !important;
    flex-direction: column !important;
    gap: 1rem !important;

    > .area_banner {
        border: 1px solid #ebebeb !important;
        border-radius: 4px !important;
        background-repeat: no-repeat !important;
        background-size: 100% 100% !important;
        background-position: center center !important;
        min-height: 25rem !important;

        display: flex !important;
        justify-content: center !important;
        align-items: flex-end !important;
        margin-left: 1rem !important;
        

        width: 90% !important;

        > h6 {
            color: #fff !important;
            font-size: 2.4rem !important;
            font-weight: 500 !important;
            text-transform: uppercase !important;
            letter-spacing: 0.1rem !important;
            padding-bottom: 0.2rem !important;
            text-shadow: #000 0.1em 0.1em 0.2em;
        }

    }

    > .infos {
        display: flex !important;
        flex-direction: column !important;
        gap: 0.4rem !important;
        padding-bottom: 0.6rem !important;
        margin-left: 1rem !important;
        width: 90% !important;

        > h6 {
            text-align: center !important;
            font-size: 1.8rem !important;
        }
    }
}

@media screen and (max-width: 840px) {
    .area_how_to_study {
        > .area_banner {
            min-height: 10rem !important;
    
            > h6 {
                font-size: 1.8rem !important;
                padding-bottom: 0 !important;
            }
    
        }
    
        > .infos {
            > div {
                width: 98% !important;
            }
    
            > h6 {
                font-size: 1.4rem !important;
            }
        }
    }
}
