.area_deepening_knowledge {
    display: flex !important;
    align-items: center !important;

    margin-top: 1.2rem !important;

    width: 100% !important;
    position: relative !important;

    > .slide {
        display: flex !important;
        margin-left: 1.2rem !important;

        border-bottom: 2px solid #cbcbcb !important;
        padding: 0.8rem 0.4rem !important;
        
        max-width: 99.5rem !important;
        overflow-x: hidden !important;
        scroll-behavior: smooth !important;
    }

    > .btn_slide_right, .btn_slide_left{
        position: absolute !important;
        top: 45% !important;

        background: #fff !important;
        border: 1px solid #cbcbcb !important;
    }

    > .btn_slide_right{
        
        right: 5rem !important;
    }

    > .btn_slide_left {
        margin-right: 0.4rem !important;
    }
}

@media screen and (max-width: 840px) {
    .area_deepening_knowledge {
        margin-top: 0 !important;
    
        > .slide {
            margin-left: 0 !important;
    
            padding: 0.8rem 1rem !important;
            
            max-width: 100% !important;
            overflow-x: auto !important;
            scroll-behavior: smooth !important;
        }
    
        > .btn_slide_right, .btn_slide_left{
            display: none !important;
        }
    }   
}
