.dialog_title_notepad {
    background: var(--red-primary);

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0.6rem !important;

    > .text_title {
        color: #fff !important;
        font-size: 1.1rem !important;
    }

    > svg {
        color: #fff !important;
        font-size: 2.4rem !important;
    }
}

.dialog_title_notepad.fal {
    background: var(--blue-primary);
}

.editor {
    height: 32rem !important;
}

.jodit-status-bar {
    display: none !important;
}

@media screen and (max-width: 840px){
    .dialog_title_notepad {
        > h2 {
            font-size: 1.4rem !important;
        }
    
        > svg {
            font-size: 1.6rem !important;
        }
    }

    .editor {
        height: 20rem !important;
    }   
}