.area_study_guide {
    margin-top: 1.2rem !important;

    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;

    > .paper {
        width: 80% !important;
        display: flex !important;
        flex-direction: column !important;
        
        > div > div > div > button {
            font-size: 1.6rem !important;
        }

        > button {
            align-self: flex-end !important;
            margin: 0 1rem 1rem 0 !important;
        }
    }

    > .area_actions {
        width: 80%;
        padding-top: 1rem !important;
    }
}