.area_not_found {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    height: 80vh !important;

    > div {
        width: 50% !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;

        > .main_not_found > span {
            font-size: 10rem !important;
            font-family: 'Orbitron', sans-serif !important;
            color: var(--red-primary) !important;
        }

        > .main_not_found.fal > span {
            color: var(--blue-primary) !important;
        }
    
        > .title_not_found {
            font-size: 2rem !important;
            margin-bottom: 1.4rem !important;
            text-align: center !important;
            color: var(--red-primary-light) !important;
        }

        > .title_not_found.fal {
            color: var(--blue-primary-light) !important;
        }
    
        > .subtitle_not_found {
            font-size: 1.2rem !important;
            color: #484848aa !important;
            margin-bottom: 1rem !important;
            text-align: center !important;

            > span {
                font-weight: bold !important;
                font-size: 1.2rem !important;
                color: var(--red-primary) !important;
            }
        }

        > .subtitle_not_found.fal {
            > span {
                color: var(--blue-primary) !important;
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .area_not_found {
        > div {
            width: 95% !important;
    
            > .main_not_found > span {
                font-size: 6rem !important;
            }
        
            > .title_not_found {
                font-size: 1.8rem !important;
            }
        
            > .subtitle_not_found {
                font-size: 1rem !important;
    
                > span {
                    font-size: 1rem !important;
                }
            }
        }
    }
}