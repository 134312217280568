.area_sharing_ideas {
    display: flex !important;
    flex-direction: column !important;
    gap: 1.2rem !important;
    justify-content: center !important;
    align-items: center !important;

    margin-top: 1.4rem !important;

    > h3 {
        font-weight: 200 !important;
        font-size: 1.4rem !important;
        width: 90% !important;
    }

    > div {
        display: flex !important;
        justify-content: flex-end !important;
        width: 90% !important;
        padding: 0.8rem !important;
    }
}