.card_resource {
    display: flex !important;
    flex-direction: column !important;
    gap: 1.2rem !important;
    background: #F1F1FA !important;
    justify-content: space-between !important;

    width: 22rem !important;
    min-height: 18rem !important;
    padding: 0.6rem !important;
    margin: 0.6rem !important;


    > .header_card_resource {
        display: flex !important;
        align-items: center !important;
        gap: 1.2rem !important;

        > h6 {
            font-weight: 700 !important;
            font-size: 1.2rem !important;
        }
    }

    > .content_card_resource {
        > h6 {
            color: rgba(0, 0, 0, 0.438) !important;
            letter-spacing: 0.25px !important;
            line-height: 1.8rem !important;
            font-size: 1.1rem !important;
        }
    }

    > .actions_card_resource {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;

        > svg {
            color: #666 !important;
        }

        > svg.check {
            color: #0A7541 !important;
        }

        > button {
            color: #0A7541 !important;
            font-size: 1rem !important;
        }
    }
}

@media screen and (max-width: 840px) {
    .card_resource {
        gap: 0.8rem !important;
    
        min-width: 18rem !important;
        padding: 0.4rem !important;
        margin: 0.4rem !important;
    
        > .header_card_resource {
            gap: 1rem !important;
        }
    
        > .content_card_resource {
            > h6 {
                letter-spacing: 0.2px !important;
                line-height: 1.4rem !important;
                font-size: 1rem !important;
            }
        }
    }   
}
