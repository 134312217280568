.area_pratical_class {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    gap: 0.6rem !important;
    margin-top: 2rem !important;
    width: 80% !important;

    > h6 {
        font-size: 1.2rem !important;
        color: #484848 !important;
    }
}

@media screen and (max-width: 840px) {
    .area_pratical_class {
        margin-top: 1rem !important;
        width: 90% !important;
        padding: 0 0.6rem !important;
    }
}