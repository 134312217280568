:root {
    --red-primary: #B50000;
    --blue-primary: #001D7E;

    --red-primary-light: #B50000B9;
    --blue-primary-light: #001D7EB9;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font-size: 15px;
    font-family: 'DM Sans', sans-serif !important;
}