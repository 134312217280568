.area_nav_info {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    gap: 1.2rem !important;

    width: 100% !important;
    padding: 8px 0 !important;
    text-transform: uppercase !important;

    > :first-child {
        display: flex !important;
        flex-direction: column !important;
        gap: 0.6rem !important;

        > button {
            align-self: flex-start !important;
        }
    }

    > h6 {
        font-weight: 500 !important;
    }
}

.progress {
    height: 0.4rem !important;
    border-radius: 4px !important;
}

.title_navigation {
    font-weight: 500 !important;
    font-size: 1rem !important;
    text-transform: uppercase !important;
    width: 100% !important;
}

.item_nav {
    padding: 0 !important;

    &.optional {
        & > div > div > svg:first-child {
            color: #161719 !important;
        }
    }

    &.active {
        background-color: var(--red-primary) !important;
        color: #fff !important;
    
        svg {
            color: #fff !important;
        }

        & > div > div > svg:first-child {
            color: #fff !important;
        }

        & > div > div > svg:last-child {
            color: #fff !important;
        }
    }

    &.active.fal {
        background-color: var(--blue-primary) !important;
    }

    & > div > div > svg:first-child {
        color: #161719 !important;
    }

    & > div > div > svg:last-child {
        color: #161719 !important;
    }

    .void {
        color: #cbcbcb !important;
        text-align: center !important;
    }

    svg + svg {
        margin-left: 0.4rem !important;
    }
}