.area_emojis {
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    width: 100% !important;

    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;

    > div {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        padding: 1rem !important;

        border: 1px solid #cbcbcb !important;
        border-radius: 4px !important;
        background-color: #f1f1f6 !important;

        width: 18% !important;

        > span {
            font-size: 2.4rem !important;
        }

        &.excellent {
            background: #129f0550 !important;
            border: 1px solid #129f05 !important;
            border-radius: 4px !important;

            > h6 {
                color: #14550efd !important;
            }
        }

        &.well {
            background: #0b32a769 !important;
            border: 1px solid #0b32a7 !important;
            border-radius: 4px !important;

            > h6 {
                color: #0b32a7 !important;
            }
        }

        &.bad {
            background: #fd8f2970 !important;
            border: 1px solid #fd8f29 !important;
            border-radius: 4px !important;

            > h6 {
                color: #fd8f29 !important;
            }
        }

        &.very_bad {
            background: #b5000052 !important;
            border: 1px solid #b50000 !important;
            border-radius: 4px !important;

            > h6 {
                color: #b50000 !important;
            }
        }

        &.regular {
            background: #540ba756 !important;
            border: 1px solid #550ba7 !important;
            border-radius: 4px !important;

            > h6 {
                color: #550ba7 !important;
            }
        }


        &.active {
            border: 1px solid #fdbc29 !important;
            border-radius: 4px !important;
            background-color: #f7a91421 !important;

            > h6 {
                color: #c88b00 !important;
            }
        }

        &:hover {
            cursor: pointer !important;
            border: 1px solid #fdbc29 !important;
            border-radius: 4px !important;
            background-color: #f7a91421 !important;

            > h6 {
                color: #c88b00 !important;
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .area_emojis {
        > div {
            padding: 0.6rem !important;
    
            > span {
                font-size: 1.8rem !important;
            }

            > h6 {
                font-size: 0.9rem !important;
            }
    
            &.active {
                cursor: pointer !important;
                border: 1px solid #fdbc29 !important;
                border-radius: 4px !important;
                background-color: #f7a91421 !important;
    
                > h6 {
                    color: #c88b00 !important;
                }
            }
    
            &:hover {
                cursor: pointer !important;
                border: 1px solid #fdbc29 !important;
                border-radius: 4px !important;
                background-color: #f7a91421 !important;
    
                > h6 {
                    color: #c88b00 !important;
                }
            }
        }
    }
}