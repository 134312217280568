.area_unit_summary {
    display: flex !important;
    flex-direction: column !important;
    gap: 1.6rem !important;

    > h2 {
        display: flex !important;
        align-items: center !important;

        font-size: 2rem !important;
        font-weight: 500 !important;
        padding-left: 1rem !important;
    }

    .area_gols {
        padding: 0.8rem !important;

        display: flex !important;
        flex-direction: column !important;
        gap: 0.4rem !important;

        > h5 {
            font-size: 1.4rem !important;
            font-weight: 500 !important;
            color: #2c2c2c !important;
        }

        > h6 {
            border-radius: 4px !important;
            padding: 1rem !important;

            * {
                text-align: justify !important;
                text-indent: 1.5em;
                font-size: 1.2rem !important;
                font-weight: normal !important;
                line-height: 1.5 !important;
            }
        }

        &::after {
            content: "" !important;
            height: 0.1rem !important;
            width: 100% !important;
            background: #e1e1f6 !important;
            margin-top: 1rem !important;
        }
    }

    .area_summary {
        padding: 0 0.8rem !important;

        display: flex !important;
        flex-direction: column !important;
        gap: 0.4rem !important;
        margin-bottom: 1rem !important;

        > h5 {
            font-size: 1.4rem !important;
            font-weight: 500 !important;
            color: #2c2c2c !important;
        }

        > h6 {
            border-radius: 4px !important;
            padding: 1rem !important;

            * {
                text-align: justify !important;
                text-indent: 1.5em;
                font-weight: normal !important;
                font-size: 1.2rem !important;
                line-height: 1.5 !important;
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .area_unit_summary {
        gap: 0.4rem !important;
    
        > h2 {
            font-size: 1.4rem !important;
            padding: 0 0.2rem !important;
            text-align: center !important;
        }
    
        .area_gols {
            padding: 0.4rem !important;
            gap: 0 !important;
    
            > h5 {
                font-size: 1.2rem !important;
            }
    
            > h6 {
                padding: 0 0.6rem !important;
            }
    
            &::after {
                margin-top: 0.6rem !important;
            }
        }
    
        .area_summary {
            padding: 0 0.4rem !important;
    
            gap: 0.2rem !important;
    
            > h5 {
                font-size: 1.2rem !important;
            }
    
            > h6 {
                padding: 0.2rem !important;
            }
        }
    }
}