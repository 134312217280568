.area_contributes {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 1rem !important;

    padding-bottom: 5rem !important;

    > .box_actions_new_and_filter {
        width: 95% !important;

        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        align-self: flex-start !important;
        margin-left: 1.2rem !important;

        > div {
            min-width: 10rem !important;
        }
    }

    .void {
        font-size: 1.4rem !important;
        color: #969595 !important;
        border-radius: 4px !important;
        border: 1px solid #aeaeae4c !important;
        padding: 0.6rem !important;

        > small {
            display: block !important;
            text-align: center !important;
        }
    }
}